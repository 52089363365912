<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">中孚验证-灵验宝</div>
      <el-form :model="userinfo" :rules="rules" ref="login" label-width="0px" class="ms-content">
        <el-form-item prop="username">
          <el-input v-model="userinfo.username" placeholder="用户名">
            <template #prepend>
              <el-button icon="el-icon-user"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              type="password"
              placeholder="密码"
              v-model="userinfo.password"
              @keyup.enter="submitForm()" show-password
          >
            <template #prepend>
              <el-button icon="el-icon-lock"></el-button>
            </template>
          </el-input>
        </el-form-item>
        <div class="login-btn">
          <el-button type="primary" @click="submitForm()">登录</el-button>
        </div>
        <div>
          <a href="/resetpassword" style="color:white;float: right;">忘记密码</a>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import USER from "../api/base/api_user";

export default {
  data() {
    return {
      lock: false,
      userinfo: {ostype: 1, logintype: 0, username: "", password: ""},
      rules: {
        username: [{required: true, message: "请输入用户名", trigger: "blur"}],
        password: [{required: true, message: "请输入密码", trigger: "blur"}]
      }
    };
  },
  created() {
    this.$store.commit("clearTags");
  },
  methods: {

    submitForm() {
      this.$refs.login.validate(valid => {
        if (valid) {
          this.login();
        } else {
          this.$message.error("请输入账号和密码");
          return false;
        }
      });
    },
    login() {
      if (this.lock) return;
      this.lock = true;
      USER.login(this.userinfo).then((res) => {
        this.lock = false;
        this.login_res(res);
      }).finally(() => {
        this.lock = false;
      });
    },
    login_res(res) {
      if (res && res.code === 200) {
        localStorage.setItem("access-token", res.token);
        this.$store.commit("update_user_auth", res.auth);
        this.$store.commit("update_cuttuser", res.user);
        setTimeout(()=>{
          this.$router.push("/");
        },10)
      } else {
        this.$message.error(res.msg || "登录失败");
      }
    }
  }
}
;
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login-bg.jpg);
  background-size: 100%;
}

.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: white;
  border-bottom: 1px solid #ddd;
}

.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 450px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.4);
  overflow: hidden;
}

.ms-content {
  padding: 30px 30px;
}

.login-btn {
  text-align: center;
}

.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}

.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>